import { AuthContext, IAuthContextProvider } from "../AuthProvider"
import { DateTimeFormatContext, IDateTimeProvider } from "../DateTimeProvider"
import { Dispatch, SetStateAction, useContext, useState } from "react"

import { ILanguageProvider } from "../LangProvider";
import { MenuList } from "app/views/ProtectedPage/views/LeftSideBar/PortalMenu/MenuItems/types";
import { NavigationTranslations } from "app/helpers/utils/i18n/types/navigation";
import { getPortalNavigationItems } from "app/views/ProtectedPage/views/LeftSideBar/PortalMenu/MenuItems";
import { useTranslations } from "../LangProvider/functions/useTranslations"
import { useYupTranslations } from "../LangProvider/functions/useYupTranslations";

export interface IAppContext {
    dateContext: IDateTimeProvider;
    authContext: IAuthContextProvider;
    langContext: ILanguageProvider;
    goBackButton: IGoBackButtonProps;
    navigation: AppNavigation
    widgets: WidgetContext
}

export interface AppNavigation {
    menu: Array<MenuList>;
    setMenu: Dispatch<SetStateAction<MenuList[]>>
}

export interface IGoBackButtonParams {
    link: string;
    translateKey: keyof NavigationTranslations;
}

export interface IGoBackButtonProps {
    state: IGoBackButtonParams | undefined;
    setGoBackButton: Dispatch<SetStateAction<IGoBackButtonParams | undefined>>
}

export interface WidgetContext {
    widgetStatus: WidgetStatus
    setWidgetStatus: Dispatch<SetStateAction<WidgetStatus>>
    isIframe: boolean
}

interface WidgetStatus {
    unauthorized: boolean;
    error: boolean
}


export function useAppProvider() {
    const [state, setState] = useState<IGoBackButtonParams | undefined>(undefined);
    const [menu, setMenu] = useState<MenuList[]>(getPortalNavigationItems({}));
    const [widgetStatus, setWidgetStatus] = useState<WidgetStatus>({
        unauthorized: false,
        error: false
    });

    const isIframe = window.self !== window.top;

    const app_context: IAppContext = {
        authContext: useContext(AuthContext),
        dateContext: useContext(DateTimeFormatContext),
        langContext: { getTranslations: useTranslations, getValidationTranslations: useYupTranslations },
        goBackButton: { state, setGoBackButton: setState },
        navigation: { menu, setMenu },
        widgets: { isIframe, widgetStatus, setWidgetStatus}
    }

    return { app_context }
}