export type AddFilterReduxForm = {
    field: string;
    operator: string;
    value: string;
    logic: string;
};

export enum SortingKind {
    Asc = "asc",
    Desc = 'desc',
};

export type CommonPaginatedRequestDataModelWithFilter = {
    offset: number;
    limit: number;
    sortField?: string;
    sortKind?: SortingKind;
    filters?: AddFilterReduxForm[];
};

export type CommonPaginatedRequestDataModel = Omit<CommonPaginatedRequestDataModelWithFilter, 'filters'>
