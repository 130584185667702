import { createAppRoute } from "./helpers/functions/createAppRoute";

/**
 * Contains Routes of Application
 */
export const AppUriPath = (() => ({
    signIn: createAppRoute('/login'),
    signUp: createAppRoute('/signup'),
    subscriptions: createAppRoute('/subscriptions'),
    subscriptionSetup: createAppRoute('/subscription-setup'),
    onboarding: createAppRoute('/subscription-setup/onboarding'),
    invoices: createAppRoute('/invoices'),
    account: createAppRoute('/account'),
    addons: createAppRoute('/addons'),
    plans: createAppRoute('/plans'),
    charges: createAppRoute('/charges'),
    createSinglePayment: createAppRoute('/single-payment'),
    notfound: createAppRoute('/notfound'),
    checkout: createAppRoute('/checkout'),
    translations: createAppRoute('/lexems'),
    session: createAppRoute('/sessions/authorize'),
    widgets: createAppRoute('/widgets'),
    widgets_invoiceCard: createAppRoute('/widgets/invoice-card'),
}))();