import { AppDispatch, RootState } from 'app/store/store';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { actionCreators } from '../slices/actionCreators';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useMemo } from 'react';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppState: TypedUseSelectorHook<RootState> = useSelector;

export const useActions = () => {
    const dispatch = useAppDispatch();
    return useMemo(() => bindActionCreators(actionCreators, dispatch), [dispatch]);
};