import { PortalConfigurationsDto, ProjectBillingInfo, ReceiveFrequenciesResponseDto, UserInfoDto } from "app/api/endpoints/configurations/types";

import { rest } from "msw";

export const portalConfigsMock: PortalConfigurationsDto = {
    logoUrl: `${process.env.PUBLIC_URL}/img/logo.svg`,
    faviconUrl: `${process.env.PUBLIC_URL}/img/favicon.png`,
    brandColor: "#4354ff",
    currencies: ["USD"],
    id: "rainex-default-project-id",
    isActive: true,
    languages: ["en"],
    subDomain: "default",
    createdAt: "",
    invoiceSettings: {
        advanceInvoiceBeforeEndDays: 1,
        autoCollection: 'on',
        createInvoiceAfterStartPeriod: false,
        daysNotifyAboutUnpaidAdvanceInvoice: []
    },
    subscriptionSettings: {
        includeTax: false,
        startOption: "after_payment",
        allowDownGradePlan: true,
        allowToDeleteAddons: true,
        allowUpgradePlan: true,
        cancelOption: "cancel_unavailable",
        changeOption: "immediately_with_prorate"
    },
    customAgreement: "",
    singlePaymentSettings: {
        autoCollection: "on",
        includeTax: true
    },
    hasAddons: true,
    hasCharges: true,
    hasPlans: true
};

export const projectBillingInfo: ProjectBillingInfo = {
    companyName: "",
    house: "",
    street: "",
    apartment: "",
    city: "",
    zipCode: "",
    country: "",
    email: "",
    phone: "",
    bankDetails: "",
    vatNumber: ""
}

export const projectFrequenciesMock: ReceiveFrequenciesResponseDto = {
    "items": [
        {
            "id": "138",
            "name": "Daily",
            "unitOfMeasurement": "Days",
            "status": "Active",
            "quantity": 1
        },
        {
            "id": "139",
            "name": "Weekly",
            "unitOfMeasurement": "Weeks",
            "status": "Active",
            "quantity": 1
        },
        {
            "id": "140",
            "name": "Monthly",
            "unitOfMeasurement": "Months",
            "status": "Active",
            "quantity": 1
        },
        {
            "id": "141",
            "name": "Yearly",
            "unitOfMeasurement": "Years",
            "status": "Active",
            "quantity": 1
        }
    ],
    "offset": 0,
    "limit": 10,
    "totalCount": 4
}

export const projectCurrenciesMock = {
    "items": [
        {
            "isoCode": "USD",
            "isMain": true
        },
        {
            "isoCode": "EUR",
            "isMain": false
        },
        {
            "isoCode": "JPY",
            "isMain": false
        }
    ],
    "offset": 0,
    "limit": 10,
    "totalCount": 3
}

export const userInfoMock: UserInfoDto = {
    hasSession: false,
    hasUser: true,
    email: "testuser@mail.com",
    userId: "testuserid",
    userName: "testusername",
    sessionId: null,
    customerId: "testcustomerid"
}

export const portalConfigsEndPointMocks = [
    rest.get("*/configurations", (req, res, ctx) => res(ctx.json(portalConfigsMock))),
    rest.get("*/billing-info", (req, res, ctx) => res(ctx.json(projectBillingInfo))),
    rest.post("*/project-frequencies", (req, res, ctx) => res(ctx.json(projectFrequenciesMock))),
    rest.post("*/currencies", (req, res, ctx) => res(ctx.json(projectCurrenciesMock))),
    rest.get("*/me", (req, res, ctx) => res(ctx.json(userInfoMock))),
]