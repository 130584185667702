import "react-toastify/dist/ReactToastify.css";

import { ToastOptions, toast } from "react-toastify";

import { PortalConfigurationsDto } from "app/api/endpoints/configurations/types";
import { localStorageUtil } from "./localStorageUtil";
import { parseJSONToModel } from "../functions/parseJSONToModel";

export enum MessageType {
  Default,
  Success,
  Error,
  Info,
  Warning
}

export const showFloatMessage = (type: MessageType, message: string, options?: ToastOptions<{}>) => {
  const projectInfo = localStorageUtil().getItem("projectInfo");
  const { brandColor } = parseJSONToModel<PortalConfigurationsDto>(projectInfo ?? "");
  switch (type) {
    case MessageType.Success: {
      toast.success(message, { ...options, style: { backgroundColor: brandColor ?? "#4354FF" } });
      break;
    }

    case MessageType.Error: {
      toast.error(message, options);
      break;
    }

    case MessageType.Warning: {
      toast.warning(message, options);
      break;
    }

    case MessageType.Info: {
      toast.info(message, options);
      break;
    }

    default: {
      toast.dark(message, options);
      break;
    }
  }
};
