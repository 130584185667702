import { BaseQueryApi, FetchArgs } from "@reduxjs/toolkit/query";

import { QueryFn } from "app/helpers/entities/QueryFn";

export const trimStrings = (obj: any) => {
    if (obj !== null && typeof obj === 'object') {
        const newObj: any = Array.isArray(obj) ? [] : {};
        Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === 'string') {
                newObj[key] = obj[key].replace(/ {2,}/g, ' ').trim();
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                newObj[key] = trimStrings(obj[key]);
            } else {
                newObj[key] = obj[key];
            }
        });
        return newObj;
    }
    return obj;
};

function skipTrim(body: any): boolean {
    return (
        body instanceof Blob ||
        body instanceof File ||
        body instanceof ArrayBuffer ||
        body instanceof FormData ||
        body instanceof URLSearchParams
    );
}

export const withTrimm = (queryFn: QueryFn) => async (args: FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
    if (!skipTrim(args.body)) {
        const mutableArgs = JSON.parse(JSON.stringify(args));
        const trimmedArgs = {
            ...args,
            body: trimStrings(mutableArgs.body),
        };
        return queryFn(trimmedArgs, api, extraOptions);
    } else {
        return queryFn(args, api, extraOptions);
    }
}