import * as icons from './icons'

export const CustomIcons = {
    subscriptions: icons.SubscriptionSVG,
    accountSvg: icons.AccounSvg,
    invoicesSvg: icons.InvoicesSVG,
    plansSvg: icons.PlansSVG,
    addonsSvg: icons.AddonsSVG,
    signoutSvg: icons.SignOutSVG,
    chargesSvg: icons.ChargesSVG
}