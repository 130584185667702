import { AppUriPath } from "app/AppRoutes";
import { CustomIcons } from "app/helpers/icons";
import { MenuList } from "./types";

type GetPortalNavigationParams = {
    hasPlans?: boolean;
    hasAddons?: boolean;
    hasCharges?: boolean;
    hasSubscriptions?: boolean;
}

export const getPortalNavigationItems = ({ hasAddons = true, hasCharges = true, hasPlans = true, hasSubscriptions = true }: GetPortalNavigationParams): MenuList[] => {
    return [
        {
            link: AppUriPath.subscriptions,
            icon: CustomIcons.subscriptions,
            title: 'subscriptions',
            isVisibile: hasSubscriptions,
        },
        {
            link: AppUriPath.plans,
            icon: CustomIcons.plansSvg,
            title: 'plans',
            isVisibile: hasPlans,
        },
        {
            link: AppUriPath.addons,
            icon: CustomIcons.addonsSvg,
            title: "addons",
            isVisibile: hasAddons,
        },
        {
            link: AppUriPath.charges,
            icon: CustomIcons.chargesSvg,
            title: "charges",
            isVisibile: hasCharges,
        },
        {
            link: AppUriPath.invoices,
            icon: CustomIcons.invoicesSvg,
            title: 'invoices',
            isVisibile: true,
        },
        {
            link: AppUriPath.account,
            icon: CustomIcons.accountSvg,
            title: "account",
            isVisibile: true,
        },
    ]
}