import React, { PropsWithChildren } from "react";

import { I18nextProvider } from 'react-i18next';
import i18n from "app/helpers/utils/i18n/I18n";
import { useTranslations } from "./functions/useTranslations";
import { useYupTranslations } from "./functions/useYupTranslations";

export interface ILanguageProvider {
    getTranslations: typeof useTranslations;
    getValidationTranslations: typeof useYupTranslations
}

export const LanguageProvider: React.FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
    return <I18nextProvider i18n={i18n}>
        {children}
    </I18nextProvider>
}