import { useEffect, useState } from "react";

export function usePageTitle() {
    const [title, setTitle] = useState("");
    useEffect(() => {
        const target = document.querySelector('title') as Node;
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((_mutation) => {
                setTitle(document.title);
            });
        });

        observer.observe(target, {
            childList: true,
        });

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        setTitle(document.title)
    }, [])

    return { title }
}