import { PortalConfigurationsDto, UserInfoDto } from "../types";
import i18n from 'i18next';
import { localStorageUtil } from "app/helpers/utils/localStorageUtil";

export function transformResponse(baseQueryReturnValue: PortalConfigurationsDto) {
    const { setItem } = localStorageUtil();
    const lng = baseQueryReturnValue.languages[0] ?? "enDefault";

    if (!baseQueryReturnValue) return baseQueryReturnValue;
    setItem('projectInfo', JSON.stringify(transformValues(baseQueryReturnValue)));
    setItem('i18nextLng', lng);
    i18n.changeLanguage(lng);
    return baseQueryReturnValue;
}

export function transformResponseUserInfo(baseQueryReturnValue: UserInfoDto) {
    const { setItem } = localStorageUtil();

    if (!baseQueryReturnValue) return baseQueryReturnValue;
    setItem('userInfo', JSON.stringify(baseQueryReturnValue));
    return baseQueryReturnValue;
}

const transformValues = (baseQueryReturnValue: PortalConfigurationsDto): PortalConfigurationsDto => {
    return {
        ...baseQueryReturnValue,
        logoUrl: baseQueryReturnValue.logoUrl ? baseQueryReturnValue.logoUrl : `${process.env.PUBLIC_URL}/img/logo.svg`,
        faviconUrl: baseQueryReturnValue.faviconUrl ? baseQueryReturnValue.faviconUrl : `${process.env.PUBLIC_URL}/img/favicon.png`,
        brandColor: baseQueryReturnValue.brandColor ? baseQueryReturnValue.brandColor : "#4354FF",
    }
}