import { Link, SxProps, Theme } from "@mui/material";
import React, { HTMLAttributeAnchorTarget } from "react";

import { useNavigate } from "react-router-dom";
import { useProjectInfo } from "app/views/components/hooks/useProjectInfo";

type OwnProps = {
    link?: string;
    text: string;
    target?: HTMLAttributeAnchorTarget;
    isLink?: boolean;
    onClick?: () => void;
    sx?: SxProps<Theme>
};

export const LinkButton = (props: OwnProps) => {
    const { link, onClick, text, target, isLink } = props;
    const { brandColor } = useProjectInfo();
    const navigate = useNavigate();

    const onClickHandler = () => {
        if (link) return navigate(link);
        if (onClick) return onClick()
    }

    return <Link
        href={isLink ? link : undefined}
        target={target}
        onClick={onClickHandler}
        sx={{ cursor: "pointer", color: brandColor, ...props.sx }}
        underline="none"
    >
        {text}
    </Link>
}