import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Avatar } from '@mui/material';
import { LinkButton } from 'app/views/components/common/LinkButton';
import React from "react";
import cn from "classnames";
import css from '../../styles/styles.module.css';
import { useAppContext } from 'app/providers/hooks/useAppContext';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from "app/views/components/hooks/usePageTitle";
import { useProjectInfo } from 'app/views/components/hooks/useProjectInfo';
import useWindowDimension from 'app/views/components/hooks/useWindowDimension';
import { AppUriPath } from 'app/AppRoutes';
import { goBackNavigate } from 'app/helpers/functions/goBackNavigate';

export const TopSideBar: React.FC = () => {
    const { title } = usePageTitle();
    const { goBackButton: { state }, langContext: { getTranslations } } = useAppContext();
    const { logoUrl } = useProjectInfo();
    const { isMobileSized } = useWindowDimension();
    const navigate = useNavigate();

    const { translate } = getTranslations("navigation")

    const renderGoBackButton = () => (
        <div style={{ display: 'flex', margin: '0 8px' }}>
            <div style={{ paddingTop: '6px', cursor: 'pointer' }} onClick={() => goBackNavigate(AppUriPath.subscriptions, navigate)}>
                <ArrowBackIosIcon sx={{ color: 'gray', fontSize: '14px', marginRight: '16px' }} />
            </div>
            <div style={{ fontSize: '14px', display: "flex", flexDirection: "column" }}>
                <LinkButton
                    text={translate(state!.translateKey)}
                    link={state!.link}
                />
                <span>{title}</span>
            </div>
        </div>
    );

    const showGobackButton = !!state?.link && !!state.translateKey;

    return <div className={cn(css["top-side-bar"])} data-testid="TopSideBar">
        <div style={{ display: "flex", alignItems: 'center', gap: '8px', justifyContent: "space-between", width: "100%" }}>
            {
                isMobileSized &&
                <Avatar
                    sx={{ width: "auto", height: "auto", maxWidth: "80px" }}
                    variant="square"
                    src={logoUrl}
                />
            }
            {(showGobackButton && !isMobileSized) ? renderGoBackButton() : <span>{title}</span>}
        </div>
    </div>
}
