import { Draft, PayloadAction } from "@reduxjs/toolkit";

import { IReduxFormReducerState } from "../state";
import { IReduxFormUpdateSucceedActionPayload } from "../types";

export const updateReduxFormReducer = (state: Draft<IReduxFormReducerState>, action: PayloadAction<IReduxFormUpdateSucceedActionPayload>): Draft<IReduxFormReducerState> => {
  const payload = action.payload as IReduxFormUpdateSucceedActionPayload;

  if (state.hasOwnProperty(payload.formName)) {
    state[payload.formName] = {
      ...state[payload.formName],
      currentValues: {
        ...state[payload.formName].currentValues,
        ...payload.values,
      },
      initialValues: state[payload.formName].initialValues,
    };
  }
  return state;
};
