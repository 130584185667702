import { List } from "@mui/material";
import React from "react";
import { SimpleMenuItemView } from "./SimpleMenuItemView";
import { useAppContext } from "app/providers/hooks/useAppContext";

export const PortalMenuView: React.FC = () => {
    const { navigation: { menu } } = useAppContext();
    const visibileMenuItems = menu.filter(it => it.isVisibile);

    return <List style={{ width: '100%', marginTop: '16px' }} disablePadding>
        {
            visibileMenuItems.map(menu => <SimpleMenuItemView key={menu.link} {...menu} />)
        }
    </List>
}