export type CommonListResponseDataModel<TResult> = {
    items: Array<TResult>;
    pagination: PaginationDataModel;
}

export type PaginationDataModel = {
    offset: number;
    limit: number;
    totalCount: number;
}

export type CommonListResponseDto<TResult> = {
    offset: number;
    limit: number;
    totalCount: number;
    items: Array<TResult>;
}

export function commonResponseMapFor <TInputResult extends TOutputResult, TOutputResult>(
    value: CommonListResponseDto<TInputResult>,
    outputResultMapFrom?: (input: TInputResult) => TOutputResult): CommonListResponseDataModel<TOutputResult> {
        return {
            items: value.items.map(item => outputResultMapFrom? outputResultMapFrom(item) : item),
            pagination: {
                limit: value.limit,
                offset: value.offset,
                totalCount: value.totalCount
            }
        }
    }