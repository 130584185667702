// Define forbidden prefixes and related types to enforce valid path rules
const forbiddenPrefixes = ['api', 'widgets-api', 'project-ui', 'project-api', 'internal'] as const;
type ForbiddenPrefix = typeof forbiddenPrefixes[number];

// Validate paths and generate an error type if a forbidden prefix is detected
type InvalidPathError<T extends string> = `Error: '${T}' starts with a forbidden prefix '${ForbiddenPrefix}'`;
type ValidPath<T extends string> = T extends `${infer P}${ForbiddenPrefix}/${string}`
    ? P extends "" | "/"
    ? InvalidPathError<T>
    : T
    : T;

// Function to create a valid route while enforcing lowercase paths
export function createAppRoute<T extends string>(fullPath: ValidPath<T>) {
    return fullPath.toLowerCase();
};
