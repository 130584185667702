import { AddonsListResponseDto, ReceiveAddonsResponseDto } from "./types";
import { CommonPaginatedRequestDataModelWithFilter, WebVerb } from "app/helpers/entities";

import { PortalApi } from "app/api";

export const addonsEndPoints = PortalApi.injectEndpoints({
    endpoints: builder => ({

        getAddons: builder.query<AddonsListResponseDto, CommonPaginatedRequestDataModelWithFilter>({
            query: body => ({
                url: '/addons',
                method: WebVerb.POST,
                body: body
            }),
            transformResponse: (response: ReceiveAddonsResponseDto) => response.items
        })

    })
});

