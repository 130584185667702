import { useEffect } from 'react';

export function useFavicon(newFaviconUrl: string) {
    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = newFaviconUrl;

        const existingFavicon = document.querySelector('link[rel="icon"]');

        if (existingFavicon) {
            document.head.removeChild(existingFavicon);
        }
        document.head.appendChild(link);

        return () => {
            document.head.removeChild(link);
        };
    }, [newFaviconUrl]);
}
