import React, {PropsWithChildren} from "react";

import {AppProvider} from "./AppProvider";
import {AuthProvider} from "./AuthProvider";
import {DateTimeProvider} from "./DateTimeProvider";
import {LanguageProvider} from "./LangProvider";
import {Provider as StoreProvider} from "react-redux";
import {store} from "app/store/store";

export const Provider: React.FC<PropsWithChildren> = ({children}: PropsWithChildren) => {
    return <StoreProvider store={store}>
        <LanguageProvider>
            <DateTimeProvider>
                <AuthProvider>
                    <AppProvider>
                        {children}
                    </AppProvider>
                </AuthProvider>
            </DateTimeProvider>
        </LanguageProvider>
    </StoreProvider>
}