import { QueryFn } from "app/helpers/entities/QueryFn";
import { retry } from "@reduxjs/toolkit/dist/query";

type ErrorData = Record<string, string> | undefined;

const RetryStatuses: Array<any> = [400];
const RetryErrorCodes: Array<any> = ["ConcurrencyError"];
const RetryAttemtsCount: number = 3;
const RetryDelaysInMs: Record<number, number> = {
    1: 10,
    2: 100,
    3: 1000
};

export const withRetry = (queryFn: QueryFn) => retry(queryFn, {
    retryCondition: (error, _args, extraArgs) => {
        const data = error.data as ErrorData;
        return (RetryErrorCodes.includes(data?.errorCode) && RetryStatuses.includes(error.status) && extraArgs.attempt < RetryAttemtsCount)
    },
    backoff: async (attempt) => {
        await new Promise(resolve => {
            setTimeout(resolve, RetryDelaysInMs[attempt])
        });
    }
});