import { PortalApi } from "../../API";
import { transformResponse } from "./transforms";
import { WebVerb } from "app/helpers/entities";
import { GetAccessTokenRequest, GetAccessTokenResponse } from "./types/get-access-token";

export const widgetsAuthorizationEndPoints = PortalApi.injectEndpoints({
    endpoints: builder => ({
        getAccessToken: builder.mutation<GetAccessTokenResponse, GetAccessTokenRequest>({
            query: ({ accessToken, sessionId }) => ({
                body: {
                    accessToken,
                    ...(sessionId && { sessionId }),
                },
                url: '/authorize/access-session',
                method: WebVerb.POST,
            }),
            transformResponse: transformResponse,
        }),
    })
})
