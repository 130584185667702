import React, {PropsWithChildren, useEffect} from "react";

import { AppUriPath } from "app/AppRoutes";
import BottomNavigation from "./views/BottomNavigation";
import { ContentBlock } from "./views/ContentBlock";
import { LeftSideBar } from "./views/LeftSideBar";
import { Navigate } from "react-router-dom";
import { TopSideBar } from "./views/TopSideBar";
import cn from "classnames";
import css from "./styles/styles.module.css";
import { useProtectedPages } from "./hooks/useProtectedPages";

type OwnProps = PropsWithChildren & {
    hideNavbar?: boolean;
    hideTopSideBar?: boolean;
    content?: JSX.Element;
};

export const ProtectedPage: React.FC<OwnProps> = ({ children, hideNavbar, hideTopSideBar, content }: OwnProps) => {
    const { isActive, isAuthenticated, isMobileSized } = useProtectedPages();

    useEffect(() => {
        if (isAuthenticated) {
            const event = new CustomEvent('hubspotConnection');
            document.dispatchEvent(event);
        }
    }, [isAuthenticated]);

    if (!isAuthenticated) return <Navigate to={AppUriPath.signIn} />;
    if (!isActive) return <Navigate to={AppUriPath.notfound} />;
    return <div className={cn(css["layout"])}>
        {(!hideNavbar && !isMobileSized) && <LeftSideBar />}
        <div className={cn(css["container"])} style={{ width: hideNavbar ? "100%" : undefined }}>
            {!hideTopSideBar && <TopSideBar />}
            {!!content && content}
            {!!children && <ContentBlock children={children} />}
            {(!hideNavbar && isMobileSized) && <BottomNavigation />}
        </div>
    </div>
}