import { MessageType, showFloatMessage } from "app/helpers/utils/showFloatMessage";
import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";

import { AppUriPath } from "app/AppRoutes";
import { getApiErrorTranslation } from "./getApiErrorTranslation";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import { localStorageUtil } from "app/helpers/utils/localStorageUtil";

export type ErrorActionResponseType = {
  error: object;
  meta: any;
  type: string;
  payload: {
    data: { description?: string, data: any, errorCode: string };
    status: number;
    hideErrorMessage?: boolean;
  }
}
/**
 * Log a warning and show a toast
 */
export const rtkQueryErrorLogger: Middleware = (_api: MiddlewareAPI) => (next) => (action: ErrorActionResponseType) => {
  const hideErrorMessage = !!action?.payload?.hideErrorMessage;
  const { removeItem } = localStorageUtil();

  const isFramePage = window.self !== window.top

  if (hideErrorMessage) {
    return next(action);
  }

  const shouldLogout = (status: number) => {
    return status === 401 || status === 403;
  }

  if (isRejectedWithValue(action) && shouldLogout(action.payload.status)) {
    if (isFramePage) {
      const authKey = getAuthenticatedKeyForRemove();

      authKey && removeItem(authKey);
      return next(action);
    }

    removeItem('isAuthenticated');
    removeItem('userInfo');
    removeItem("localStorageForm");
    window.location.replace(AppUriPath.signIn);
    return next(action);
  }

  if (isRejectedWithValue(action)) {
    showFloatMessage(MessageType.Error, getApiErrorTranslation(action));
  }

  return next(action);
};

function getAuthenticatedKeyForRemove(): string | null {
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get("sessionId");

  return `${sessionId}_isWidgetAuthenticated`;
}