import React, { ComponentType, Suspense } from 'react';

/**
 * Shows the loading element while the Component is not loading
 * @param Component asynchronous component via React.lazy(import('.../path/to/component/...').then(({ Component }) => ({ default: Component }))
 */
export function asyncComponent<TProps, TClass extends ComponentType<any>>(factory: () => Promise<TClass>, componentProps?: TProps): ComponentType<TProps> {
    return () => {
        const loadComponentInfo = factory().then(ComponentClass => ({ default: ComponentClass }));
        const Component = React.lazy<TClass>(() => loadComponentInfo);
        return (
            <Suspense fallback={null}>
                <Component {...componentProps as any} />
            </Suspense>
        );
    };
}