export const customFetch = async (input: RequestInfo, init?: RequestInit) => {
    const response = await fetch(input, init);
    if (response.redirected) {
        const redirectUrl = response.url;
        if (redirectUrl) {
            window.location.href = redirectUrl;
            return new Response(JSON.stringify({ redirected: true, url: redirectUrl }));
        }
    }
    return response;
}