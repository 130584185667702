import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { IReduxFormReducerState } from "../state";
import { IReduxFormResetSucceedActionPayload } from "../types";

export const resetReduxFormReducer = (state: Draft<IReduxFormReducerState>, action: PayloadAction<IReduxFormResetSucceedActionPayload>): Draft<IReduxFormReducerState> => {
    const payload = action.payload as IReduxFormResetSucceedActionPayload;

            if(state.hasOwnProperty(payload.formName)) {
                delete state[payload.formName]
            }

            return state
}