import { CommonPaginatedRequestDataModelWithFilter, WebVerb } from "app/helpers/entities";
import { CurrenciesListDto, FrequenciesListDto, PortalConfigurationsDto, ProjectBillingInfo, ReceiveCurrenciesResponseDto, ReceiveFrequenciesResponseDto, UserInfoDto } from "./types";

import { PortalApi } from "app/api";
import { transformResponse, transformResponseUserInfo } from "./functions/transformResponse";

export const portalConfigsEndPoints = PortalApi.injectEndpoints({
    endpoints: builder => ({

        getPortalConfigs: builder.query<PortalConfigurationsDto, void>({
            query: () => ({
                url: '/configurations',
                method: WebVerb.GET
            }),
            transformResponse: transformResponse
        }),

        getUserInfo: builder.query<UserInfoDto, void>({
            query: () => ({
                url: `/me`,
                method: WebVerb.GET
            }),
            transformResponse: transformResponseUserInfo
        }),

        getProjectBillingInfo: builder.query<ProjectBillingInfo, void>({
            query: () => ({
                url: '/billing-info',
                method: WebVerb.GET
            })
        }),

        getProjectFrequencies: builder.query<FrequenciesListDto, CommonPaginatedRequestDataModelWithFilter>({
            query: body => ({
                url: '/project-frequencies',
                method: WebVerb.POST,
                body: body
            }),
            transformResponse: (response: ReceiveFrequenciesResponseDto) => response.items,
        }),

        getProjectCurrencies: builder.query<CurrenciesListDto, CommonPaginatedRequestDataModelWithFilter>({
            query: body => ({
                url: '/currencies',
                method: WebVerb.POST,
                body: body
            }),
            transformResponse: (response: ReceiveCurrenciesResponseDto) => response.items,
        }),
    })
})
