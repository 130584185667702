import { CommonPaginatedRequestDataModelWithFilter, WebVerb } from "app/helpers/entities";
import { PaymentSource, ReceivePaymentSourcesDto } from "./types";

import { PortalApi } from "app/api";

export const paymentSourceEndPoints = PortalApi.injectEndpoints({
    endpoints: builder => ({
        getPaymentMethods: builder.query<Array<PaymentSource>, CommonPaginatedRequestDataModelWithFilter>({
            query: body => ({
                url: `/customer-payment-sources/query`,
                method: WebVerb.POST,
                body: body
            }),
            transformResponse: (response: ReceivePaymentSourcesDto) => response.items,
            providesTags: ["payment-methods"]
        }),

        addPaymentMethod: builder.mutation<string, string>({
            query: currencyCode => ({
                url: `/customer-payment-sources/${currencyCode}`,
                method: WebVerb.POST,
            }),
            invalidatesTags: ["payment-methods"]
        }),

        deletePaymentMethod: builder.mutation<void, string>({
            query: paymentId => ({
                url: `/customer-payment-sources/${paymentId}`,
                method: WebVerb.DELETE
            }),
            invalidatesTags: ["payment-methods"]
        }),

        setAsPrimary: builder.mutation<void, string>({
            query: paymentId => ({
                url: `/customer-payment-sources/set-primary-payment-source/${paymentId}`,
                method: WebVerb.POST
            }),
            invalidatesTags: ["payment-methods"]
        })
    })
})
