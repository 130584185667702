import { SendOTPRequestParams, SendTokenAccessDto, SendTokenAccessRequestParams, SignInWithOTPRequestParams, SignInWithPasswordRequestParams, SignOutDto } from "./types";

import { PortalApi } from "app/api";
import { WebVerb } from "app/helpers/entities";

export const authenticationEndPoints = PortalApi.injectEndpoints({
    endpoints: builder => ({

        sendOTP: builder.mutation<void, SendOTPRequestParams>({
            query: ({ email, otpOption = "auth" }) => ({
                url: `/customers/send-otp`,
                method: WebVerb.POST,
                body: { email, otpOption }
            })
        }),

        signInWithOTP: builder.mutation<void, SignInWithOTPRequestParams>({
            query: body => ({
                url: `/customers/sign-in/otp`,
                method: WebVerb.POST,
                body: body
            })
        }),

        signInWithPassword: builder.mutation<void, SignInWithPasswordRequestParams>({
            query: body => ({
                url: `/customers/sign-in`,
                method: WebVerb.POST,
                body: body
            })
        }),

        signUp: builder.mutation<string, SignInWithPasswordRequestParams>({
            query: body => ({
                url: `/customers/sign-up`,
                method: WebVerb.POST,
                body: body
            })
        }),

        signOut: builder.mutation<SignOutDto, void>({
            query: body => ({
                url: `/customers/sign-out`,
                method: WebVerb.POST,
                body: body
            })
        }),
        sendTokenAccess: builder.mutation<SendTokenAccessDto, SendTokenAccessRequestParams>({
            query: body => ({
                url: `/sessions/access`,
                method: WebVerb.POST,
                body: body
            })
        }),
    })
})
