export type TimeFormatType =
    | "HH_MM"
    | "HH_MM_SS_TZ";

export type DateFormatType =
    | "YYYY"
    | "DD"
    | "MMM"
    | "DD_MMM"
    | "MMM_YYYY"
    | "DD_MMM_YYYY"
    | "DD_MMM_YYYY_WD"
    | "DD_YYYY";

export type DateTimeFormatType = TimeFormatType | DateFormatType;

export const DateTimeFormats: Record<
    DateTimeFormatType,
    Intl.DateTimeFormatOptions
> = {
    YYYY: { year: "numeric" },
    DD: { day: "2-digit" },
    MMM: { month: "long" },
    DD_MMM: { day: "2-digit", month: "long" },
    MMM_YYYY: { month: "long", year: "numeric" },
    DD_MMM_YYYY_WD: { day: "2-digit", month: "short", year: "numeric", weekday: "short" },
    DD_MMM_YYYY: { day: "2-digit", month: "short", year: "numeric" },
    DD_YYYY: { day: "2-digit", year: "numeric" },
    HH_MM: { hour: "2-digit", minute: "2-digit" },
    HH_MM_SS_TZ: { hour: "2-digit", minute: "2-digit", second: "2-digit", timeZoneName: "long" }
};