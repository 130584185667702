import { Avatar, Drawer } from "@mui/material";

import { PortalMenuView } from "./PortalMenu";
import React from "react";
import { SignOutLink } from "./SignOut";
import cn from 'classnames';
import css from '../../styles/styles.module.css';
import { useProjectInfo } from "app/views/components/hooks/useProjectInfo";

export const LeftSideBar: React.FC = () => {
    const { logoUrl } = useProjectInfo();

    return <Drawer
        variant='permanent'
        data-testid="LeftSideBar"
        classes={{
            paper: cn(css['left-side-bar'])
        }}
        sx={{ width: '240px' }}
        anchor='left'
    >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", padding: "16px 8px", paddingBottom: 0 }}>
            <Avatar
                sx={{ width: "auto", height: "auto", maxWidth: "100px" }}
                variant="square"
                src={logoUrl}
            />
        </div>
        <PortalMenuView />
        <div style={{ margin: "auto 0 16px 0" }}>
            <SignOutLink />
        </div>
    </Drawer>
}