import { useEffect, useRef } from 'react'

import { PageTitlesTranslations } from 'app/helpers/utils/i18n/types/page-titles';
import { useAppContext } from 'app/providers/hooks/useAppContext';

export function useDocumentTitle(title: keyof PageTitlesTranslations, prevailOnUnmount = false) {
    const defaultTitle = useRef(document.title);
    const { langContext: { getTranslations } } = useAppContext();
    const { translate } = getTranslations("pageTitles");

    useEffect(() => {
        document.title = translate(title);
    }, [title, translate]);

    useEffect(() => () => {
        if (!prevailOnUnmount) {
            document.title = defaultTitle.current;
        }
    }, [prevailOnUnmount])
};