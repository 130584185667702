import {
    ChargesListDto,
    CreateSinglePaymentRequestParams,
    CreateSinglePaymentResponseDto,
    DeleteSinglePaymentRequestParams,
    ReceiveChargesResponseDto,
    ReceiveSinglePaymentsResponseDataModel,
    ReceiveSinglePaymentsResponseDto
} from "./types";
import { CommonPaginatedRequestDataModelWithFilter, WebVerb, commonResponseMapFor } from "app/helpers/entities";

import { PortalApi } from "app/api/API";

export const singlePaymentEndpoints = PortalApi.injectEndpoints({
    endpoints: builder => ({
        getChargesList: builder.query<ChargesListDto, CommonPaginatedRequestDataModelWithFilter>({
            query: body => ({
                url: "/charges",
                body: body,
                method: WebVerb.POST,
            }),
            transformResponse: (response: ReceiveChargesResponseDto) => response.items
        }),

        createSinglePayment: builder.mutation<CreateSinglePaymentResponseDto, CreateSinglePaymentRequestParams>({
            query: body => ({
                url: "/single-payments",
                body: body,
                method: WebVerb.POST
            }),
            invalidatesTags: ["invoices-list-query", "single-payments", "invoices-list"]
        }),

        getSinglePayments: builder.query<ReceiveSinglePaymentsResponseDataModel, CommonPaginatedRequestDataModelWithFilter>({
            query: body => ({
                url: "/single-payments/query",
                body: body,
                method: WebVerb.POST
            }),
            transformResponse: (response: ReceiveSinglePaymentsResponseDto) => commonResponseMapFor(response, item => item),
            providesTags: ["single-payments"]
        }),

        cancelSinglePayment: builder.mutation<void, DeleteSinglePaymentRequestParams>({
            query: body => ({
                url: `/single-payments/${body.id}`,
                method: WebVerb.DELETE,
                invalidatesTags: ["single-payments"]
            })
        }),
    })
})