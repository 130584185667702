import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { enDefault } from './default';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        fallbackLng: 'enDefault',
        debug: false,
        lng: "en",
        detection: {
            order: ['localStorage'],
            caches: ['localStorage']
        },
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: `${process.env.REACT_APP_TRANSLATIONS_HOST}/{{lng}}.json`,
        },
    });
i18n.addResourceBundle('enDefault', 'translation', enDefault)


export default i18n;