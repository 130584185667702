export const localStorageUtil = () => {
    return {
        setItem: (key: string, value: any) => window.localStorage.setItem(key, value),
        getItem: (key: string) => window.localStorage.getItem(key),
        removeItem: (key: string) => window.localStorage.removeItem(key),
        removeItems: (keys: string | Array<string>) => {
            if (typeof keys === "string") {
                window.localStorage.removeItem(keys);
                return;
            }
            keys.forEach(key => window.localStorage.removeItem(key));
        }
    }
}
