import { CommonPaginatedRequestDataModelWithFilter, WebVerb } from "app/helpers/entities";
import { FamilyItemPricesResponseDto, PlansListResponseDto, PriceListResponseDto, ReceivePlansResponseDto } from "./types/index";

import { PortalApi } from "app/api";

export const plansEndPoints = PortalApi.injectEndpoints({
    endpoints: builder => ({

        getPlans: builder.query<PlansListResponseDto, CommonPaginatedRequestDataModelWithFilter>({
            query: body => ({
                url: '/plans',
                method: WebVerb.POST,
                body: body
            }),
            transformResponse: (response: ReceivePlansResponseDto) => response.items
        }),

        getFamilyItemPrices: builder.query<PriceListResponseDto, CommonPaginatedRequestDataModelWithFilter>({
            query: body => ({
                url: "/item-prices/query",
                body: body,
                method: WebVerb.POST
            }),
            transformResponse: (response: FamilyItemPricesResponseDto) => response.items
        })
    })
});

