import rgba from "color-rgba";

export function colorToRGBAwithOpacity(color: string): string | null {
    color = color.trim();
    const rgb = rgba(color);
    if (!rgb) {
        return null
    }
    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${0.08})`

}