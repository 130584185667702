import { addonsEndPoints } from "../endpoints/addons"
import { authenticationEndPoints } from "../endpoints/authentication"
import { customersEndPoints } from "../endpoints/customers"
import { invoicesEndPoints } from "../endpoints/invoices"
import { paymentSourceEndPoints } from "../endpoints/payment-sources"
import { plansEndPoints } from "../endpoints/plans"
import { portalConfigsEndPoints } from "../endpoints/configurations"
import { singlePaymentEndpoints } from "../endpoints/single-payment"
import { subscriptionsEndPoints } from "../endpoints/subscriptions"
import {widgetsAuthorizationEndPoints} from "../endpoints/widgets-authorization";

export const getApies = () => {
    return {
        authenticationEndPoints,
        portalConfigsEndPoints,
        customersEndPoints,
        invoicesEndPoints,
        subscriptionsEndPoints,
        plansEndPoints,
        addonsEndPoints,
        singlePaymentEndpoints,
        paymentSourceEndPoints,
        widgetsAuthorizationEndPoints
    }
}