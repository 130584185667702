import './index.css';

import { Suspense } from 'react';
import App from './App';
import { BackdropLoader } from 'app/views/components/common/BackdropLoader';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<Suspense fallback={<BackdropLoader open />}><App /></Suspense>);

// Отмена регистрации Service Worker
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
            registration.unregister();
        });
    });
}

// Очистка кешей, связанных с Service Worker
if ('caches' in window) {
    caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
            caches.delete(cacheName);
        });
    });
}
