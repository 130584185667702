export interface SecurityUtil {
    readonly accessToken: string;
    readonly expiresIn: number;
    readonly isAuthenticated: boolean;
    readonly checkIsTokenExpired: () => boolean;
    readonly getTimeBeforeExpiration: () => number;
    readonly setProjectCredentialsWithSessionId: (args: ProjectCredentialsWithSessionIdModel) => boolean;
    readonly deleteProjectCredentials: () => boolean;
}

export type ProjectCredentialsModel = {
    widgetAccessToken: string;
    widgetExpiresIn: number;
}

export type ProjectCredentialsWithSessionIdModel = ProjectCredentialsModel;

const SecurityUtilEnum = {
    isAuthenticated: "isWidgetAuthenticated",
    accessToken: "widgetAccessToken",
    expiresIn: "widgetExpiresIn",
} as const;

export class SecurityUtilClass implements SecurityUtil {
    private readonly secondsBeforeUpdateToken = 200;
    private readonly sessionId: string | null = null;

    private constructor(sessionId: string) {
        this.sessionId = sessionId;
    }

    public static getInstance(): SecurityUtilClass {
        const sessionId = SecurityUtilClass.extractSessionIdFromQuery();
        if (!sessionId) {
            throw new Error("Session ID is required.");
        }
        return new SecurityUtilClass(sessionId);
    }

    private static extractSessionIdFromQuery(): string | null {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("sessionId");
    }

    public set expiresIn(value: number) {
        const key = this.getSessionKey();
        window.localStorage.setItem(key.expiresIn, value.toString());
    }

    public get expiresIn() {
        const key = this.getSessionKey();
        return window.localStorage.getItem(key.expiresIn) ? Number(window.localStorage.getItem(key.expiresIn)) : 0;
    }

    public set accessToken(value: string) {
        const key = this.getSessionKey();
        window.localStorage.setItem(key.accessToken, value);
    }

    public get accessToken() {
        const key = this.getSessionKey();
        return window.localStorage.getItem(key.accessToken) ?? '';
    }

    public get isAuthenticated() {
        const key = this.getSessionKey();
        return Boolean(window.localStorage.getItem(key.isAuthenticated))
    }

    public set isAuthenticated(value: boolean) {
        const key = this.getSessionKey();
        value
            ? window.localStorage.setItem(key.isAuthenticated, "auth")
            : window.localStorage.removeItem(key.isAuthenticated)
    }

    public checkIsTokenExpired() {
        const currentTime = Date.now() / 1000;
        const expirationTime = this.expiresIn - this.secondsBeforeUpdateToken;
        return currentTime > expirationTime;
    }

    public getTimeBeforeExpiration() {
        const currentTime = Date.now() / 1000;
        const expirationTime = this.expiresIn;
        return Math.max(expirationTime - currentTime, 0);
    }

    public setProjectCredentialsWithSessionId(args: ProjectCredentialsWithSessionIdModel) {
        this.isAuthenticated = true;
        this.accessToken = args.widgetAccessToken;
        this.expiresIn = Date.now() / 1000 + args.widgetExpiresIn;
        return true;
    }

    public deleteProjectCredentials() {
        const key = this.getSessionKey();
        this.isAuthenticated = false;
        window.localStorage.removeItem(key.expiresIn)
        window.localStorage.removeItem(key.accessToken)
        return true;
    }

    public clearExpiredSessions() {
        const now = Date.now() / 1000;

        for (let i = 0; i < window.localStorage.length; i++) {
            const key = window.localStorage.key(i);

            if (key && key.includes('_widgetExpiresIn')) {
                const expiresIn = window.localStorage.getItem(key);
                if (expiresIn && parseFloat(expiresIn) < now) {
                    const sessionId = key.split('_')[0];

                    Object.keys(window.localStorage).forEach(k => {
                        if (k.startsWith(sessionId)) {
                            window.localStorage.removeItem(k);
                        }
                    });
                }
            }
        }
    }

    private getSessionKey() {
        if (!this.sessionId) {
            throw new Error("Session ID is not set.");
        }
        return {
            expiresIn: `${this.sessionId}_${SecurityUtilEnum.expiresIn}`,
            accessToken: `${this.sessionId}_${SecurityUtilEnum.accessToken}`,
            isAuthenticated: `${this.sessionId}_${SecurityUtilEnum.isAuthenticated}`
        };
    }
}

export const getGlobalSecurityInstance = () => {
    return SecurityUtilClass.getInstance();
};