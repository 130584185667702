import { DateFormatType, DateTimeFormats, TimeFormatType } from "./types";

export interface IDateTimeFormatter {
    formatDateTime: (
        date: Date,
        dateFormat?: DateFormatType,
        timeFormat?: TimeFormatType
    ) => string;
}
export class DateTimeFormatter implements IDateTimeFormatter {
    private language;

    constructor(lang: string) {
        this.language = lang;
    }

    public formatDateTime = (
        date: Date,
        dateFormatType?: DateFormatType,
        timeFormatType?: TimeFormatType
    ) => {
        try {
            if (!dateFormatType && !timeFormatType) {
                return Intl.DateTimeFormat(this.language, {
                    ...DateTimeFormats["DD_MMM_YYYY"],
                    ...DateTimeFormats["HH_MM"]
                }).format(date);
            }

            return Intl.DateTimeFormat(this.language, {
                ...(dateFormatType && DateTimeFormats[dateFormatType]),
                ...(timeFormatType && DateTimeFormats[timeFormatType])
            }).format(date);
        }
        catch (err) {
            console.log(this.language, err)
            return date.toLocaleString()
        }
    };
}