import * as React from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

type OwnProps = {
    open: boolean;
}

export const BackdropLoader = ({ open }: OwnProps) => {
    return <Backdrop
        data-testid="backdrop-loader"
        sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
    >
        <CircularProgress data-testid="backdrop-loader-circular-progress" color="inherit" />
    </Backdrop>;
}