import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { PortalApi } from "app/api/API";
import { reduxFormReducer } from "./slices/redux-form-slice";
import { rtkQueryErrorLogger } from "app/api/functions";
import { catalogReducer } from "./slices/catalog-slice";

export const AppReducers = combineReducers({
    reduxForm: reduxFormReducer,
    catalog: catalogReducer,
    [PortalApi.reducerPath]: PortalApi.reducer
});

export const store = configureStore({
    reducer: AppReducers,
    middleware: gDM => gDM().concat(PortalApi.middleware, rtkQueryErrorLogger),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
