import { getApies } from "app/api/functions/getApies";
import { useActions } from "app/store/hooks";
import { useEffect } from "react";

export function useCollectProducts() {
    const { setCatalogItems } = useActions();

    const requestArgs = { limit: 100, offset: 0 };

    const { data: addons } = getApies().addonsEndPoints.useGetAddonsQuery(requestArgs);
    const { data: plans } = getApies().plansEndPoints.useGetPlansQuery(requestArgs);
    const { data: charges } = getApies().singlePaymentEndpoints.useGetChargesListQuery(requestArgs);

    useEffect(() => {
        if (addons && plans && charges) {
            setCatalogItems([...addons, ...plans, ...charges])
        }
    }, [addons, charges, plans, setCatalogItems]);
};