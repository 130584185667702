/**
 * Contains web verbs
 */
export enum WebVerb {
    /** Request to create new entry */
    POST = 'POST',

    /** Request to read an entry */
    GET = 'GET',

    /** Request to update an entry */
    PUT = 'PUT',

    /** Request to delete an entry */
    DELETE = 'DELETE',
    
    /** Request to edit an entry */
    PATCH = 'PATCH'
}