import { useEffect, useState } from "react";

type WindowDimension = {
    scrollWidth: number;
    scrollHeight: number;
    isMobileSized: boolean;
    isMobileSM: boolean;
    isMobileXS: boolean;
};

const useWindowDimension = (): WindowDimension => {
    const [windowDimension, setWindowDimension] = useState<WindowDimension>(() => ({
        scrollWidth: 0,
        scrollHeight: 0,
        isMobileSized: false,
        isMobileSM: false,
        isMobileXS: false,
    }));

    useEffect(() => {
        function handleResize() {
            const scrollHeight = Math.max(
                document.body.scrollHeight,
                document.documentElement.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.offsetHeight,
                document.body.clientHeight,
                document.documentElement.clientHeight
            );

            const scrollWidth = Math.max(
                document.body.scrollWidth,
                document.documentElement.scrollWidth,
                document.body.offsetWidth,
                document.documentElement.offsetWidth,
                document.body.clientWidth,
                document.documentElement.clientWidth
            );

            const isMobileSized = window.innerWidth <= 768;
            const isMobileSM = window.innerWidth <= 450;
            const isMobileXS = window.innerWidth <= 320;

            setWindowDimension({
                scrollWidth,
                scrollHeight,
                isMobileSized,
                isMobileSM,
                isMobileXS
            });
        }

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return windowDimension;
};

export default useWindowDimension;
