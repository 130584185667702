import { AppUriPath } from "app/AppRoutes";

export interface CheckoutItem {
    itemPriceId: string;
    quantity: number | null;
}

export function parseCheckoutQueryParams(query: URLSearchParams): CheckoutItem[] {
    const items: CheckoutItem[] = [];

    query.forEach((paramValue, paramName) => {
        const match = paramName.match(/subscription_items\[item_price_id\]\[(\d+)\]/);
        if (match) {
            const index = parseInt(match[1], 10);
            const itemPriceId = paramValue;
            const quantityParamName = `subscription_items[quantity][${index}]`;
            const quantity = query.get(quantityParamName);
            items[index] = { itemPriceId, quantity: quantity ? parseInt(quantity, 10) : null };
        }
    });

    return items;
}

export function getNavigationPath(hasPlans?: boolean): string {
    if (hasPlans) return AppUriPath.plans;
    return AppUriPath.account;
}