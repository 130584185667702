/**
 * Object properties
 */
type TObjProps = {
    [property: string]: string | number | boolean | Date | undefined | {}
};

/**
 * Emulation of filling object fields
 * @param obj Object for which to perform fill emulation
 * @returns Emulated object with filled fields
 */
export function partialFillOf<TObj extends TObjProps>(obj?: Partial<TObj>): TObj {
    return { ...obj } as TObj;
}