import { DynamicContent } from './useTranslations';
import { ValidationNS } from 'app/helpers/utils/i18n/types/validation';
import { useTranslation as useI18nextTranslation } from 'react-i18next';

export type ValidationNamespace = keyof ValidationNS;
export type TValidationFunc<T> = <K extends keyof T>(key: K, dynamicContent?: DynamicContent | undefined) => string

export function useYupTranslations<N extends ValidationNamespace>(namespace: N) {
    const { t } = useI18nextTranslation();

    const translate = <K extends keyof ValidationNS[N]>(key: K, dynamicContent?: DynamicContent) => {
        const translationKey = `validation.${namespace}.${key as string}`;
        return t(translationKey, { ...dynamicContent });
    };

    return { translate };
}


