import { Draft, PayloadAction } from "@reduxjs/toolkit";

import { IReduxFormInitSucceedActionPayload } from "../types";
import { IReduxFormReducerState } from "../state";

export const initReduxFormReducer = (state: Draft<IReduxFormReducerState>, action: PayloadAction<IReduxFormInitSucceedActionPayload>): Draft<IReduxFormReducerState> => {
    const payload = action.payload as IReduxFormInitSucceedActionPayload;
    state[payload.formName] = {
        isInitialized: true,
        initialValues: {
            ...payload.values
        },
        currentValues: {
            ...payload.values
        }
    }
    return state
}