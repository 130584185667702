import { IAppContext, useAppProvider } from "../hooks/useAppProvider";
import { PropsWithChildren, createContext } from "react";

export const AppContext = createContext<IAppContext>(undefined as any);

export const AppProvider: React.FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
    const { app_context } = useAppProvider();

    return <AppContext.Provider value={app_context}>
        {children}
    </AppContext.Provider>
}