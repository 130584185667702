
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { ICatalogState, CatalogItemsType } from "./state";
import { ProductFamilyDto } from "app/api/endpoints/plans/types";

const initialState: ICatalogState = {
    items: []
};

export const catalogSlice = createSlice({
    name: 'catalogSlice',
    initialState: initialState,
    reducers: {
        setCatalogItems: (state: Draft<ICatalogState>, action: PayloadAction<CatalogItemsType>) => {
            const newItems = action.payload;

            const isDuplicate = (item: { productFamily: ProductFamilyDto }) => {
                return state.items.some(existingItem =>
                    existingItem.id === item.productFamily.id
                );
            };

            newItems.forEach(item => {
                if (!isDuplicate(item)) {
                    state.items.push(item.productFamily);
                }
            });

            return state;
        }
    }
})

export const catalogReducer = catalogSlice.reducer;
