import { ErrorActionResponseType } from "./rtkQueryErrorLogger";
import { hasValue } from "app/helpers/functions/hasValue";
import i18n from "app/helpers/utils/i18n/I18n";

export const getApiErrorTranslation = (action: ErrorActionResponseType) => {

    const url = action?.meta?.baseQueryMeta?.request?.url as string;
    const errorCode = action.payload?.data?.errorCode;
    const description = action.payload?.data?.description;
    const dynamicContent = action.payload?.data?.data as object;

    const translate = (key: string): string => {
        const translation = i18n.t(key, { ...dynamicContent });
        const translationExists = translation !== key;

        if ((key === "common.somethingwentwrong" && hasValue(description)) || !translationExists) {
            return description!
        }
        return translation;
    };

    const getTranslationKey = () => {
        const array = url.split("/");
        const indexOfApi = array.findIndex(it => it === "api");
        const key = array[indexOfApi + 1];
        if (!key || !errorCode || indexOfApi === -1) {
            return null
        }

        return `backend.${key}.${errorCode}`;
    }

    return Boolean(translate(getTranslationKey()!))
        ? translate(getTranslationKey()!)
        : translate("common.somethingwentwrong")
}