import { asyncComponent } from "app/views/components/asyncComponent";

export const AppPages = {
    SignInPage: asyncComponent(() => import("app/views/pages/Authentication/SignInPage").then((_) => _.SignInPage)),
    SignUpPage: asyncComponent(() => import("app/views/pages/Authentication/SignUpPage").then((_) => _.SignUpPage)),
    VerifyEmailPage: asyncComponent(() => import("app/views/pages/Authentication/VerifyEmailPage").then((_) => _.VerifyEmailPage)),
    PlansPage: asyncComponent(() => import("app/views/pages/PlansPage").then((_) => _.PlansPage)),
    AddonsPage: asyncComponent(() => import("app/views/pages/AddonsPage").then((_) => _.AddonsPage)),
    AccountPage: asyncComponent(() => import("app/views/pages/AccountPage").then((_) => _.AccountPage)),
    InvoicesPage: asyncComponent(() => import("app/views/pages/InvoicesPage").then((_) => _.InvoicesPage)),
    InvoiceCardPage: asyncComponent(() => import("app/views/pages/InvoicesPage/views/InvoiceCardPage").then((_) => _.InvoiceCardPage)),
    SubscriptionsPage: asyncComponent(() => import("app/views/pages/SubscriptionsPage").then((_) => _.SubscriptionsPage)),
    PageNotFound: asyncComponent(() => import("app/views/pages/PageNotFound").then((_) => _.PageNotFound)),
    TranslationKeys: asyncComponent(() => import("app/views/pages/TranslationKeys").then((_) => _.TranslationKeys)),
    CreateSubscription: asyncComponent(() => import("app/views/pages/SubscriptionHub/CreateSubscription").then((_) => _.CreateSubscription)),
    UpdateSubscription: asyncComponent(() => import("app/views/pages/SubscriptionHub/UpdateSubscription").then((_) => _.UpdateSubscription)),
    CheckoutPage: asyncComponent(() => import("app/views/pages/CheckoutPage").then((_) => _.CheckoutPage)),
    ChargesPage: asyncComponent(() => import("app/views/pages/ChargesPage").then((_) => _.ChargesPage)),
    CreateSinglePayment: asyncComponent(() => import("app/views/pages/SubscriptionHub/CreateSinglePayment").then((_) => _.CreateSinglePayment)),
    SessionPage: asyncComponent(() => import("app/views/pages/SessionPage").then((_) => _.SessionPage)),
    WidgetsPage: asyncComponent(() => import("app/views/pages/WidgetsPage").then((_) => _.Widgets)),
};