import {getGlobalSecurityInstance} from "app/views/pages/WidgetsPage/utils/security-util";
import {GetAccessTokenResponse} from "../types/get-access-token";

export function transformResponse(response: GetAccessTokenResponse) {
    const securityUtil = getGlobalSecurityInstance();

    response.token && securityUtil.setProjectCredentialsWithSessionId({
        widgetExpiresIn: response.expiresIn,
        widgetAccessToken: response.token,
    })

    return response
}