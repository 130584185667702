import { getApies } from "app/api/functions/getApies";
import { getPortalNavigationItems } from "../views/LeftSideBar/PortalMenu/MenuItems";
import { partialFillOf } from "app/helpers/functions/fillObjectWithUndefined";
import { useAppContext } from "app/providers/hooks/useAppContext";
import { useEffect } from "react";
import { useFavicon } from "app/views/components/hooks/useFavicon";
import { useProjectInfo } from "app/views/components/hooks/useProjectInfo";
import useWindowDimension from "app/views/components/hooks/useWindowDimension";
import { useCollectProducts } from "./useCollectProducts";

export function useProtectedPages() {
    const { isMobileSized } = useWindowDimension();

    const { authContext: { isAuthenticated }, navigation: { setMenu } } = useAppContext();
    const { faviconUrl, isActive, hasAddons, hasCharges, hasPlans } = useProjectInfo();
    useFavicon(faviconUrl);
    useCollectProducts();

    const { data = { items: [], pagination: partialFillOf() } }
        = getApies().subscriptionsEndPoints.useGetSubscriptionsQuery({ limit: 10, offset: 0 }, { skip: !isAuthenticated });

    useEffect(() => {
        const hasSubscriptions = !!data.items.length;
        setMenu(getPortalNavigationItems({ hasAddons, hasCharges, hasPlans, hasSubscriptions }))
    }, [data.items.length, hasAddons, hasCharges, hasPlans, setMenu])

    return {
        isAuthenticated,
        isActive,
        isMobileSized
    }
}