import { IReduxFormReducerState } from "./state";
import { createSlice } from "@reduxjs/toolkit";
import { initReduxFormReducer } from "./reducers/init-redux-form-reducer";
import { resetReduxFormReducer } from "./reducers/reset-redux-form-reducer";
import { updateReduxFormReducer } from "./reducers/update-redux-form-reducer";

const initialState: IReduxFormReducerState = {};

export const reduxFormSlice = createSlice({
    name: 'reduxForm',
    initialState: initialState,
    reducers: {
        initReduxFormReducer,
        updateReduxFormReducer,
        resetReduxFormReducer
    }
})

export const reduxFormReducer = reduxFormSlice.reducer;
